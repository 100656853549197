/*eslint indent: 0*/
/*eslint no-undef: 0*/

function formToJSON(form) {
	var inputs = form.querySelectorAll('[name]'),
		fields = {};

	inputs.forEach(input => {
		var name = input.getAttribute("name"),
			value = input.value;

		switch(input.type) {
			case "checkbox":
				if (input.checked) {
					if (fields[name] === undefined) {
						fields[name] = [];
					}

					fields[name].push(value);
				}
			break;
			case "radio":
				if (input.checked) {
					fields[name] = value;
				}
			break;
			default:
				fields[name] = value;
		}
	});

	return fields;
}

export default formToJSON;
