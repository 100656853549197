function removeitem(arr, value) {
	for (var i = 0; i < arr.length; i++) {
		if (arr[i] === value) {
			arr.splice(i, 1);
		}
	}

	return arr;
}

export default removeitem;
