import { insertUserData } from "./userinfo";

/**
	Crema Captcha Default Values and Checkbox Code
	Initialize different types of forms with default values and add optional checkbox
/*/

function slugify(text) {
	return text.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
}

function getCheckboxCode(settings) {
	var checkbox = settings.config.checkbox,
		selector = slugify(checkbox.selector),
		css = `
			${checkbox.parent} {margin: 1rem 0;}
			${checkbox.parent} label {font-weight: normal;}
			${checkbox.parent} label.custom-control {margin-bottom: 0;}
		`;

	document.head.insertAdjacentHTML('beforeend', `<style>${css}</style>`);

	if (settings.bootstrap == 4) {
		return `
			<label class="custom-control custom-checkbox">
			<input type="checkbox" class="custom-control-input ${selector}" name="is_human">
			<div class="custom-control-label">${checkbox.text}</div>
			</label>
		`;
	}

	if (settings.bootstrap == 5) {
		return `
			<label class="mb-3 form-check">
			<input type="checkbox" class="form-check-input ${selector}" name="is_human">
			<div class="form-check-label">${checkbox.text}</div>
			</label>
		`;
	}

	// Return generic human checkbox for unidentified/older versions of Bootstrap
	return `
		<label>
			<input type="checkbox" class="${selector}" name="is_human"> ${checkbox.text}
		</label>
	`;
}

function initForm(form, settings, userinfo) {
	var inputs = form.querySelectorAll('input, select, textarea'),
		btn = form.querySelector(settings.button),
		defaultInputs = '';

	if (settings.userinfo) {
		userinfo.then(userdata => {
			insertUserData(form, settings, userdata);
		});
	}

	// Insert optional checkbox if it is enabled
	if (settings.filters.checkbox) {
		var parent = form.querySelector(settings.config.checkbox.parent);
		parent.insertAdjacentHTML('afterbegin', getCheckboxCode(settings));
	}

	function getActionURL(actionType) {
		return `${location.protocol}//${location.hostname}${location.pathname}?modal=${actionType}`;
	}

	switch(form.getAttribute("data-type") || settings.type) {
	case 'formmail':
		var recipients = form.getAttribute("data-recipients"),
			subject = form.getAttribute("data-subject"),
			reqFields = [];

		// Create array of required fields for FormMail
		inputs.forEach(input => {
			input.required && reqFields.push(input.getAttribute("name"));
		});

		defaultInputs = `
			<input type="hidden" name="_pid" value="114166">
			<input type="hidden" name="_fid" value="N1FTNVIT">
			<input type="hidden" name="required" value="${reqFields.join(',')}">
			<input type="hidden" name="redirect" value="${getActionURL('success')}">
			<input type="hidden" name="missing_fields_redirect" value="${getActionURL('error')}">
		`;

		if (recipients) {
			defaultInputs += `<input type="hidden" name="recipient" value="${recipients}">`;
		}

		if (subject) {
			defaultInputs += `<input type="hidden" name="subject" value="${subject}">`;
		}

		if (settings.userinfo) {
			defaultInputs += '<input type="hidden" name="env_report" value="REMOTE_HOST,REMOTE_ADDR,HTTP_REFERER,HTTP_USER_AGENT">';
		}

		break;
	case 'craft2':
		defaultInputs = `
			<textarea class="output" rows="7" cols="40" name="message[body]" hidden></textarea>
			<input type="hidden" name="CRAFT_CSRF_TOKEN" value="">
			<input type="hidden" name="action" value="">
			<input type="hidden" name="redirect" value="contact?modal=success&from={fromName}">
			<input type="text" class="form-control" id="preferredKitten" name="preferredKitten" autocomplete="off" style="display: none;">
		`;

		break;
	default:
		if (settings.debug) {
			console.log("Since Crema's Captcha doesn't recognize this form type, no default fields will be added.");
		}
	}

	// Insert default fields and/or optional checkbox captcha
	btn.insertAdjacentHTML('beforebegin', defaultInputs);
}

export default initForm;
