function getBS() {
	//if (window.jQuery) {
	//	return ($.fn.modal||$.fn.tab).Constructor.VERSION.split('.')[0];
	//}

	return 5;
}

function getName(input) {
	return (input.getAttribute("id")||input.getAttribute("name")||input.getAttribute("class"));
}

export { getBS, getName };
