
/**
	Profanity Count for Crema Captcha
	Stephen Ginn at Crema Design Studio

	Loops through a list of inputs and counts the amount of profanity,
	based off the NPM badwords array:
	https://raw.githubusercontent.com/MauriceButler/badwords/master/array.js
/*/

import occurrences from "./occurrences";
import rot from "rot";

function validInput(el) {
	return el.offsetWidth > 0 && el.value && el.getAttribute('type') !== "checkbox" && el.getAttribute('type') !== "radio";
}

function countbadwords(inputs, badwords) {
	var profanityCount = 0,
		wordlist = [];

	inputs.forEach(input => {
		if (validInput(input)) {
			// Count bad words for each input
			for (var badword of badwords) {
				profanityCount += occurrences(input.value.toLowerCase(), rot(badword).toLowerCase());
			}

			// Get total wordcount for percentage calculation
			for (var word of input.value.split(" ")) {
				wordlist.push(word);
			}
		}
	});

	return {
		"count": profanityCount,
		"pct": ((profanityCount * 100) / wordlist.length).toFixed(1),
		"total": wordlist.length
	};
}

// Convert badwords
function getbadwords(badwords) {
	return badwords.map(w => rot(w));
}

export { countbadwords, getbadwords };
