/**
	Checkbox Captcha Translations Function
	Stephen Ginn at Crema Design Studio
	Updated on 2020-06-09
/*/

function getCheckText() {
	var lang = document.documentElement.getAttribute("lang"),
		translations = {
			"en": 'Click to verify you are human.',
			"es": 'Clic para verificar que es un ser humano.',
			"pt": 'Clique para verificar se você é humano.'
		};

	return translations[lang];
}

export default getCheckText;
