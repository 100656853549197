
/**
	Input Field Validation
	Stephen Ginn at Crema Design Studio

	Validates all the fields in a form against a list of filters. Returns as array.
/*/

import rot from "rot";
import {countbadwords, getbadwords} from "./profanityCount";
import formToJSON from "./serialize";
import removeitem from "./arrays";
import {getName} from "./helpers";

function validate(args) {
	var form = args.form,
		inputs = args.inputs,
		options = args.options,
		settings = args.settings,
		callback = args.callback;

	var filterlist = Object.entries(settings.filters),
		config = settings.config,
		action = form.getAttribute("data-post") || settings.action;

	var btn = form.querySelector(settings.button),
		profanity = countbadwords(inputs, config.profanity.badwords),
		errorlist = [],
		spam_score = 0;

	inputs.forEach(input => {
		// Break loop if input is invisible or doesn't have a name attribute
		if (input.offsetWidth === 0) {
			console.debug(`↳ Ignoring ${getName(input)}, since it was hidden.`, settings);
			return true;
		} else {
			console.debug(`Checking ${getName(input)}...`, settings);
		}

		for (var [filter, enabled] of filterlist) {
			// If an option is disabled, skip this loop
			if (!enabled) {
				console.debug(`↳ ${filter} filter disabled; skip this option`, settings);
				continue;
			}

			// Otherwise, run various inputs through a gauntlet of tests...
			switch(filter) {
			case 'checkbox':
				if (! form.querySelector(config.checkbox.selector).checked) {
					errorlist = removeitem(errorlist, filter);
					errorlist.push(filter);
					spam_score ++;
				}

				break;
			case 'honeypot':
				if (form.querySelector(config.honeypot.selector).value) {
					errorlist = removeitem(errorlist, filter);
					errorlist.push(filter);
					spam_score ++;
				}

				break;
			case 'russian':
				var pattern = /[\u0400-\u04FF]/;
				if (pattern.test(input.value)) {
					errorlist = removeitem(errorlist, filter);
					errorlist.push(filter);
					spam_score ++;
				}

				break;
			case 'required':
				var thisVal = false;

				if (input.type === "checkbox") {
					thisVal = input.checked;
				} else if (input.type === "radio") {
					var name = input.getAttribute("name");

					form.querySelectorAll(`input[name="${name}"]`).forEach(radio => {
						if (radio.checked) {
							console.debug("Radio is checked", settings);
							thisVal = true;
						}
					});
				} else {
					thisVal = input.value && true || false;
				}

				if (input.required && ! thisVal) {
					errorlist = removeitem(errorlist, filter);
					errorlist.push(filter);
					spam_score ++;
				}

				break;
			case 'profanity':
				if (profanity.count >= config.profanity.max || profanity.pct >= config.profanity.max_pct) {
					errorlist = removeitem(errorlist, filter);
					errorlist.push(filter);
					spam_score ++;
				}

				break;
			default:
				console.debug(`${filter} is not a valid captcha filter!`, settings);
			}
		} // end of filter loop
	});

	// Create encrypted error code. We could either show this to the user
	// or submit it to a debugging account when validation is tripped.
	var error_code = rot(errorlist.join('-').toUpperCase()),
		captcha_data = formToJSON(form);

	console.debug(captcha_data, settings);

	// If valid then enable, if invalid than disable:
	if (errorlist.length === 0) {
		console.debug("Crema Captcha: Passed all captcha test(s)", settings);

		// Prevent multiple valid callbacks
		if (form.getAttribute("data-validated") === 'true') {
			return false;
		}

		form.setAttribute("data-validated", true);

		if (callback && typeof options.valid === 'function') {
			options.valid.call(form);
		} else {
			form.setAttribute("action", action);
			form.setAttribute("method", "post");
			btn.classList.remove("disabled");
			btn.removeAttribute("disabled");
			btn.setAttribute("type", "submit");
		}
	} else {
		console.error(`Crema Captcha: Failed ${errorlist.length} captcha test(s)`);
		console.error(`Error Code: ${error_code}`);

		// Prevent multiple invalid callbacks
		if (form.getAttribute("data-validated") === 'false') {
			return false;
		}

		form.setAttribute("data-validated", false);

		if (callback && typeof options.invalid === 'function') {
			options.invalid.call(form);
		} else {
			form.removeAttribute("action");
			form.removeAttribute("method");
			btn.classList.add("disabled");
			btn.setAttribute("disabled", "disabled");
			btn.setAttribute("type", "button");
		}
	}

	sessionStorage.setItem("captcha_code", error_code);
	sessionStorage.setItem("captcha_data", JSON.stringify(captcha_data));

	console.error("Spam Score: " + spam_score);
	console.debug(errorlist, settings);
}

export { validate, getbadwords };
