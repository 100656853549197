/**
	Location Data for Craft CMS Forms
	Inserts user and location data from an external script into the form.
/*/

// Detect if promise, so we only fetch userdata once
function isPromise(value) {
	return Boolean(value && typeof value.then === 'function');
}

function getUserData(url) {
	return new Promise((resolve, reject) => {
		var userdata = JSON.parse(localStorage.getItem("userdata"));

		if (userdata) {
			console.log("parsing cached user data");
			resolve(userdata);
		} else {
			console.log("fetching new user data");
			fetch(url).then(response => {
				return response.json();
			}).then(userdata => {
				localStorage.setItem("userdata", JSON.stringify(userdata));
				resolve(userdata);
			}).catch(err => {
				reject(err);
			});
		}
	});
}

function insertUserData(form, settings, data) {
	var location = data.location,
		device = data.device,
		userdata = {
			lang: device.lang || navigator.language || navigator.userLanguage,
			loc: `${location.city}, ${location.region} ${location.zip}`,
			ip: device.hostIP,
			ua: device.agent
		},
		datastring = JSON.stringify(userdata),
		input = `<input type="hidden" name="debug" value='${datastring}'>`;

	// Populate hidden field with stringified user data JSON
	form.querySelector(settings.button).insertAdjacentHTML('beforebegin', input);
}

export { isPromise, getUserData, insertUserData };
